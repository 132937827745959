<template>
  <div class="progress-bar-budget">
    <div class="item">
      <span class="pull-left ng-binding" style="color: #828282;font-weight: 100;">Dia {{this.day_start}}</span>
      <span class="pull-right ng-binding" style="color: #828282;font-weight: 100;">Dia {{this.day_end}}</span>
      <div style="clear:both;"></div>
      <div class="progress progress-bar-success-alt relative">
        <div class="progress-bar progress-bar-success" role="progressbar" :class="{'progress-bar-danger': this.progress > 85, 'progress-bar-warning': this.progress > 70 && this.progress < 85, 'progress-bar-success': this.progress < 70 }" :style="`width:${this.progress}%`"></div>
        <div class="buble-day" :style="`left: ${this.day_progress}%;`">
          <div class="buble-day-line"></div>
          <div class="buble-day-content">
            <span class="buble-day-span">Hoje</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    day_progress: {
      type: Number,
    },
    progress: {
      type: Number,
    },
    day_start: {
      type: Number,
    },
    day_end: {
      type: Number,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>
